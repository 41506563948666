module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-apollo/gatsby-browser.js'),
      options: {"plugins":[],"uri":"https://cms.technopolisglobal.com/wp/graphql"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-creuna-headless-wordpress","short_name":"Technopolis","start_url":"/","background_color":"#00a9e0","theme_color":"#000000","display":"minimal-ui","icon":"src/images/technopolis-icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"865adcb207ef16bd748a51d54538d216"},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-source-wordpress/gatsby-browser.js'),
      options: {"plugins":[],"url":"https://cms.technopolisglobal.com/wp/graphql","verbose":true,"schema":{"perPage":50,"timeout":120000,"queryDepth":15,"circularQueryLimit":5,"typePrefix":"Wp","requestConcurrency":15,"previewRequestConcurrency":5},"html":{"useGatsbyImage":false,"imageMaxWidth":null,"fallbackImageMaxWidth":1024,"imageQuality":70,"createStaticFiles":true,"generateWebpImages":true,"generateAvifImages":false,"placeholderType":"dominantColor"},"type":{"__all":{"where":"languages: [FI,EN,EE,LT,LU,LU_FR,NO,SE]","beforeChangeNode":"./gatsby/beforeChangeNode.js"},"MediaItem":{"createFileNodes":false,"placeholderSizeName":"gatsby-image-placeholder","lazyNodes":false},"Area":{"excludeFieldNames":["campuses","contacts","contentNodes","officeSpaces","stories","meetingRoomLandingPages"]},"CampusCategory":{"excludeFieldNames":["campus","contacts","contentNodes","events","features","meetingRooms","officeSpaces","stories"]},"Campus":{},"CampusPageType":{"where":null,"beforeChangeNode":null},"Client":{"where":null,"beforeChangeNode":null},"ContentType":{"where":null,"beforeChangeNode":null},"GlobalAnnouncement":{"where":null,"beforeChangeNode":null},"Menu":{"where":null,"beforeChangeNode":null},"MenuItem":{"where":null,"beforeChangeNode":null},"PartnerCategory":{"where":null,"beforeChangeNode":null},"Partner":{"where":null,"beforeChangeNode":null},"Redirect":{"where":null,"beforeChangeNode":null},"Taxonomy":{"where":null,"beforeChangeNode":null},"Comment":{"exclude":true},"PostFormat":{"exclude":true},"StockRelease":{"exclude":true},"StockReleaseCategory":{"exclude":true},"User":{"exclude":true},"UserRole":{"exclude":true},"RootQuery":"{ excludeFieldNames: ['viewer', 'node', 'schemaMd5'], },"},"catchLinks":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-MNHPBR3","includeInDevelopment":false,"routeChangeEventName":"CHANGE_ROUTE","defaultDataLayer":null,"enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../node_modules/gatsby-plugin-piwik-pro/gatsby-browser.js'),
      options: {"plugins":[],"containerUrl":"https://technopolis.containers.piwik.pro/","siteId":"f18f0f3a-c4ef-4ff8-8509-01eec56b9e11","enabled":true},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
